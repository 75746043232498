<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>

          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-input
              v-model="seminarData.clientName"
              readonly
              style="width: 200px;"
            />
            <b-form-datepicker
              id="datepicker-placeholder1"
              v-model="query.startDate"
              class="el-def"
              placeholder="게시기간(Fr)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              id="datepicker-placeholder2"
              v-model="query.endDate"
              class="el-def"
              placeholder="게시기간(To)"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="fetchSeminar()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              상세정보 [{{ totalRecords }}] 건
            </h5>
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-dark"
              class="btn-search"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
              />
              <span> 다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        primary-key="webSeminarId"
        :items="seminarData"
        :fields="tableColumns"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
      />
    </b-card>

  </div>
</template>
<script>

import axios from '@axios'
import dayjs from 'dayjs'
import { ref, onMounted } from "@vue/composition-api"
import { getUserData } from '@/auth/utils'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { useToast } from "vue-toastification/composition"
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"

export default {
  components: {

  },
  computed: {

  },
  setup() {
    onMounted(() => {
      fetchSeminar()
    })
    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }
      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    // Query Data
    const query = ref({
      startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
      endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
      accessCode: null,
    })

    const seminarData = ref([])
    const totalRecords = ref(0)
    const tableColumns = [
      {
        key: 'webSeminarId', label: 'Id', sortable: true, thStyle: { width: '7%' },
      },
      {
        key: 'title', label: '제목', sortable: true, thStyle: { width: '20%' },
      },
      {
        key: 'startDateStr', label: '진행 일자', sortable: true, thStyle: { width: '7%' },
      },
      {
        key: 'startTime', label: '시작 시간', sortable: true, thStyle: { width: '7%' },
      },
      {
        key: 'endTime', label: '종료시간', sortable: true, thStyle: { width: '7%' },
      },
      {
        key: 'popupCount', label: '총클릭 수', sortable: false, thStyle: { width: '7%' },
      },
      {
        key: 'viewCount', label: '시청팝업 수', sortable: false, thStyle: { width: '7%' },
      },
      {
        key: 'seminarMyCount', label: '사전예약 수', sortable: false, thStyle: { width: '7%' },
      },
    ]

    const fetchSeminar = () => {
      const userData = getUserData()

      query.value.accessCode = userData.accessCode
      axios.post("/cl/seminar/list", {
        search: {
          query: query.value,
        },
        sort: {
          predicate: 'startDate',
          reverse: true,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data
          seminarData.value = items
          seminarData.value.clientName = seminarData.value[0].clientName
          totalRecords.value = totalRecord
        })
        .catch(() => {
          pushToast('danger', '조회된 데이터가 없습니다.')
        })
    }

    // TODO
    // 내부망에서만 다운로드 시 예외처리 필요함
    const excelDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.columns = [
        { header: "Id", key: "webSeminarId", width: 10 },
        { header: "제목", key: "title", width: 40 },
        { header: "진행일자", key: "startDateStr", width: 12 },
        { header: "시작시간", key: "startTime", width: 10 },
        { header: "종료시간", key: "endTime", width: 10 },
        { header: "총클릭 수", key: "popupCount", width: 10 },
        { header: "시청팝업 수", key: "viewCount", width: 10 },
        { header: "사전예약 수", key: "seminarMyCount", width: 10 },
      ]

      seminarData.value.map(e => worksheet.addRow({
        webSeminarId: e.webSeminarId,
        title: e.title,
        startDateStr: e.startDateStr,
        startTime: e.startTime,
        endTime: e.endTime,
        popupCount: e.popupCount,
        viewCount: e.viewCount,
        seminarMyCount: e.seminarMyCount,
      }))

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()

      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "웹세미나_리스트.xlsx")
    }

    return {
      query,
      seminarData,
      totalRecords,
      tableColumns,
      fetchSeminar,
      excelDownload,
    }
  },
}
</script>

<style lang="scss">
#drugInfoPreview .modal-dialog {
  max-width: 1150px;
}
</style>
